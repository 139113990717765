/*
Naming Schema :
Component Outer Class
inner class Kebab Case
all lowercase with - separating words.
//$CalcPanels-nav-button-background-color_active:  #000000;

Include  this any componets scss file
@import "src/client_customizations/scss/client_master.scss";

*/

$darkBlue: #00587c;
$green:#1D623A;
$blue:#0056B8;
$yellow:#8D4302;
$darkGray:#333333;
$gray: #505357;
$red: #f15c2a;

$primary-color:  rgb(153, 91, 0);
$twentyfive-px-border-radius: 25px;
$focus-box-shadow: 0 0 0 2px #0056B8;
$span-border-active: 4px solid $blue;



/*
    Header
*/

$Header-background-color:#fff;
$Header-border-bottom: 5px solid #f3f4f4;
$Header-link-color:#666;
$Header-navbar-link-color:#666;
$Header-navbar-toggler-border-color:$blue;
$Header-navbar-toggler-color:$blue;

/*
    Main
*/

$Main-min-height:500px;

/*
Page Layout
*/

$PageLayout-header-bold-color:#666666;
$PageLayout-link-focus-box-shadow:$focus-box-shadow;
$PageLayout-button-focus-box-shadow:$focus-box-shadow;
$PageLayout-form-control-focus-box-shadow:$focus-box-shadow;
$PageLayout-input-focus-box-shadow:$focus-box-shadow;
$PageLayout-select-focus-box-shadow:$focus-box-shadow;
$PageLayout-textarea-focus-box-shadow:$focus-box-shadow;
$PageLayout-image-gallery-thumbnail-focus-box-shadow: $focus-box-shadow;
$PageLayout-SimpleCopyPageBody-card-focus-box-shadow:$focus-box-shadow;
$PageLayout-SimpleCopyPageBody-card-background-color:white;
$PageLayout-SimpleCopyPageBody-card-link-hover-color:$darkGray;
$PageLayout-SimpleCopyPageBody-card-header-color:$darkGray;
$PageLayout-evc-card-focus-box-shadow:white;
$PageLayout-evc-card-background-color:$darkGray;
$PageLayout-evc-card-font-size:14px;
$PageLayout-evc-card-border:1px solid #dbdbdb;
$PageLayout-evc-card-color:$darkGray;
$PageLayout-evc-card-hover-border:1px solid white;
$PageLayout-evc-card-hover-box-shadow:0px 20px 41px rgba(0, 0, 0, 0.1);
$PageLayout-evc-card-hover-color:$darkGray;
$PageLayout-socialmedia-focus-box-shadow:$focus-box-shadow;
$PageLayout-faq-focus-box-shadow:$focus-box-shadow;
$PageLayout-faq-focus-color:inherit;
$PageLayout-container-background-color:#f3f4f4;
$PageLayout-legal-disclaimer-color:#666666;
$PageLayout-header-strong-color:$red;
$PageLayout-SimpleCopy-color:$darkGray;
$PageLayout-evc-card-hover-border:1px solid white;
$PageLayout-evc-card-hover-box-shadow:0px 20px 41px rgba(0, 0, 0, 0.1);
$PageLayout-evc-card-hover-color:$darkGray;


/*
    Generic Footer
*/

$GenericFooter-background-color:$darkGray;
$GenericFooter-color:$darkGray;
$GenericFooter-heading-color:#a9a9a9;
$GenericFooter-heading-font-size:18px;
$GenericFooter-links-font-size:13px;
$GenericFooter-links-line-height:25px;
$GenericFooter-links-color:#a9a9a9;
$GenericFooter-links-hover-color:#8dc63f;


/*
    CalcPanels
*/
$CalcPanels-nav-button-font-color: #000000;
$CalcPanels-nav-button-font-color_active: white;
$CalcPanels-nav-button-background-color: transparent;
$CalcPanels-nav-button-background-color_active:  #000000;
$CalcPanels-nav-button-border_color_active: #000000;
$CalcPanels-font-size: 14px;
$CalcPanels-nav-button-font-size: 16px;

/*
    EVCard
*/

$EVCard-EVCardBottom-border:1px solid #dbdbdb;


/*
    PricePanels
*/

$PricePanels-background-color: white;
$PricePanels-border: 1px solid #dbdbdb;
$PricePanels-Nav-border: 1px solid #dbdbdb;
$PricePanels-li-a-font-weight: 500;
$PricePanels-li-a-font-color: rgb(33, 37, 41);
$PricePanels-li-a-font-size: 16px;
$PricePanels-li-a-span-border_active:$span-border-active;
$PricePanels-nav-pills-nav-link-background-color_active: inherit;
$PricePanels-nav-pills-nav-link-font-color_active:  rgb(33, 37, 41);
$PricePanels-tab-pane-PanelItem-border: 1px #dbdbdb solid;
$MatchScoreBadge-background-color: white;
$MatchScoreBadge-border: 1px solid #dbdbdb;


/*
    Incentive Card
*/

$IncentiveCard-card-border-radius: $twentyfive-px-border-radius;
$IncentiveCard-card-body-color: #000;
$IncentiveCard-card-title-color: $red;
/*
    Incentive Preferences
*/

$IncentivePreferences-input-well-background-color:transparent;


/*
    Link Card
*/

$LinkCard-card-hover-body-color:#00527c;
$LinkCard-evc-card-background-color:#f2f2f2;


/*
    Charging Station
*/

$ChargingStation-card-background-color:white;

/*
    Dealer Location Card
*/

$DealerLocationCard-background-color:white;
$DealerLocationCard-border-radius:4px;
$DealerLocationCard-border:1px solid #dbdbdb;


/*
    ChargingMap
*/

$ChargingMap-map-container-border:1px solid #ccc;
$ChargingMap-legend-background:#fff;
$ChargingMap-legend-border:1px solid #ccc;
$ChargingMap-legend-border-radius:4px;
$ChargingMap-legend-font-size:0.75rem;
$ChargingMap-dealer-map-container-border:1px solid #ccc;
$ChargingMap-dealer-map-container-border-radius:4px;
$ChargingMap-dealer-legend-background:#fff;
$ChargingMap-dealer-legend-border:1px solid #ccc;
$ChargingMap-dealer-legend-border-radius:4px;


/*
    Render Item
*/

$RenderItem-title-color:#000;
$RenderItem-item-value-color:#000000;
$RenderItem-item-value-denominator-color:#252525;
$RenderItem-match-score-tr-last-child-border-top:1px solid #6f6f6f;
$RenderItem-match-score-tr-last-child-border-bottom:1px solid #6f6f6f;
$RenderItem-match-score-tr-th-color:#252525;


/*
    Button Group
*/

$ButtonGroup-btn-background-color:#f2f2f2;
$ButtonGroup-btn-hover-background-color:#5ca4d1;
$ButtonGroup-btn-active-background-color:#5ca4d1;


/*
    Input Range
*/

$InputRange-border:1px solid white;
$InputRange-runnable-track-background:#ddd;
$InputRange-slider-thumb-border-radius:50%;
$InputRange-slider-thumb-background:$blue;
$InputRange-focus-runnable-track-background:#ccc;
$InputRange-fill-lower-background:#777;
$InputRange-fill-lower-radius:10px;
$InputRange-fill-upper-background:#ddd;
$InputRange-fill-upper-radius:10px;
$InputRange-ms-thumb-radius:50%;
$InputRange-ms-thumb-background:#5ca4d1;
$InputRange-ms-fill-lower:#888;
$InputRange-ms-fill-upper:#ccc;

/*
    Event Card
*/

$EventCard-background-color: white;
$EventCard-logo-image-position-top: 10px;
$EventCard-logo-image-position-left: 170px;
$EventCard-btn-border-radius: 0px;
$EventCard-btn-background-color: white;
$EventCard-btn-color:black;
$EventCard-btn-border:1px solid black;
$EventCard-btn-hover-background-color: #FED141;
$EventCard-btn-hover-border: 1px solid #FED141;
$EventCard-btn-hover-color: black;

/*
    Event Filter Controls
*/
$EventFilter-border-top: none;
$EventFilter-btn-border-radius: 0px;
$EventFilter-btn-background-color: white;
$EventFilter-btn-color:black;
$EventFilter-btn-hover-background-color: black;
$EventFilter-btn-hover-border: 1px solid black;
$EventFilter-btn-hover-color: white;
$EventFilter-btn-active-background-color: black;
$EventFilter-btn-active-border: 1px solid black;
$EventFilter-btn-active-color: white;
/*
    Pages: Homepage
*/

$HomePage-big-promise-padding:120px 40px;
$HomePage-big-promise-background-color:rgba(0, 0, 0, 0.5);
$HomePage-big-promise-color:rgba(255, 255, 255, 0.9);
$HomePage-big-promise-text-shadow:2px 2px 3px rgba(255, 255, 255, 0.1);
$HomePage-big-promise-min-height:400px;
$RenderItem-match-score-value-greaterThanEightyFive-color: $green;
$RenderItem-match-score-value-greaterThanSeventy-color: $yellow;
$RenderItem-match-score-value-lessThanSeventy-color: $darkGray;
/*
    Pages: EVs
*/

$EVsSort-label-font-weight:400;
$EVsSort-label-font-size:12px;
$EVsSort-label-color:#000000;
$EVsSort-label-letter-spacing:0.5px;
$EVsSort-label-line-height:16px;
$EVsSort-select-background:#ffffff;
$EVsSort-select-border:1px solid #dbdbdb;
$EVsSort-select-border-radius:4px;
$EVsSort-select-font-size:14px;
$EVsSort-select-color:#666666;
$EVsSort-select-letter-spacing:0;

/* Pages Compare Vehicles */
$CompareVehicles-ViewVehicle-button-color: $darkBlue;
$CompareVehicles-ViewVehicle-button-fontcolor: #ffffff;
/* Pages Compare Vehicles */
$CompareVehicleToggle-MobileToggle-button-color: $darkBlue;
$CompareVehicleToggle-MobileToggle-button-fontcolor: #ffffff;

$CompareVehicleToggle-MobileToggle-button-fontcolor-active:#ffffff;
$CompareVehicleToggle-MobileToggle-button-color-active: $darkGray;

/*
    Pages: Events
*/

$Events-background-color: #E5E5E5;


/* Maps */
.google-map-cluster-marker {
    width: 40px;
    height: 52px;
    color: #000;
    // background: #1978c8;
    background-image:
        url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 80.555 100' enable-background='new 0 0 80.555 100' xml:space='preserve'><path d='M76.389,26.389V18.75c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-8.333V18.75  c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-4.167v4.167c0,5.947,4.157,10.915,9.723,12.18v15.598  l4.156,30.662c-0.059,3.014-2.518,5.449-5.545,5.449c-3.062,0-5.553-2.489-5.556-5.55h0.001V50.005L58.333,50  c0-4.595-3.738-8.333-8.333-8.333h-8.334V8.102C41.667,3.634,37.929,0,33.333,0h-25C3.738,0,0,3.634,0,8.102V100h41.667V47.222H50  c1.531,0,2.777,1.247,2.777,2.778l0.001,0.005v38.883h-0.001c0,6.127,4.984,11.111,11.111,11.111C70.015,100,75,95.016,75,88.889  l-4.167-30.744V42.735c5.565-1.265,9.722-6.232,9.722-12.18v-4.167H76.389z M10.524,73.299l5.983-11.965H9.028l10.47-17.95h7.478  L19.498,55.35h8.974L10.524,73.299z M36.111,29.398c0,1.404-1.247,2.546-2.778,2.546h-25c-1.531,0-2.778-1.142-2.778-2.546V8.102  c0-1.404,1.247-2.546,2.778-2.546h25c1.531,0,2.778,1.142,2.778,2.546V29.398z'/></svg>");
    border-radius: 10%;
    padding: 4px;
    display: flex;
    justify-content: left;
    align-items: top;

    .text{
        background-color: #fff;
        height: 12px;
    }
}
.google-map-location-marker-button {
    background-color: transparent;
    border: none;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 17px;
     text-decoration: none;
    text-shadow: 0px 1px 0px #2f6627;
    :hover {
        background-color: transparent;
    }
    :active {
        position: relative;
        top: 1px;
    }
    :hover {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        z-index: 9999;

    }
    :focus {
        outline: none;
    }
}

.main-title {
    padding:75px 0 50px;    
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;

    h2 {
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 900;
        font-size: 42px;
        line-height: 48px;
        
        display: flex;
        align-items: center;
        text-align: center;
        
        color: #333333;
        margin: 0 auto;
    }

    .contain-text {
        width:100%;
        display: flex;
        justify-content: center;

        p {
            max-width: 700px;
            font-family: 'Avenir';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            
            display: flex;
            align-items: center;
            text-align: center;
            
            color: #333333;
            display:block;
        }
    }
}

.MatchScoreOptions {
    margin-top:50px;

    p.h2 {
        margin-bottom: 25px;
    }

    .form-group {
        margin:25px 0 !important;

        label {
            font-weight: 400;
            margin-bottom: 10px;
        }
    }

    .btn.btn-sm {
        background-color:transparent;
        border:none;
        padding:0px;
        text-transform: none;
        color:$blue;
    }
    

    .left-align {
        display: flex;
        justify-content: flex-start;
    }
}

.filter-controls {
    p.h2 {
        margin-bottom: 25px;
    }

    .form-group {
        margin:25px 0 !important;

       span.label-style, label {
            font-weight: 400;
            display:block;
            margin-bottom: 10px;
        }

        .btn-grid-container {
            .btn {
                padding:0.5rem 0.75rem;
                border-radius: 0px;
                background-color: #fff;
                color:$blue;

                &.active {
                    border: 1px solid #231F20;
                    background-color: #231F20;
                    color:#fff;
                }
            }
        }
    }

    .filter-switch {
        span.badge {
            img {
                width: 25px;
            }
        }

        .switch {
            float: right;
        }

        .clearfix {
            clear: both;
        }
    }
}

p.ev-disclaimer {
    padding:5px 15px;
    font-size:12px;
}

#ev-jumbotron-title {
    background-color: #f3f7fb;
    padding:25px 0;

    h1 {
        position: relative;
        top:25px;
    }

    .price-buttons {
        display:flex;
        justify-content: space-between;

        a {
            .btn-ae {
                display: flex;
                justify-content: center;
                align-items: center;                
                width: auto;
                min-width: 163px;
                height: 48px;
                background-color:white;
                color:$blue;
                border:1px solid $blue;

                &.blue {
                    background-color:$blue;
                    border:1px solid $blue;
                    color:white;
                }
            }
        }
    }
}

#ev-jumbotron-details {
    background-color: #fff !important;

    .show-full-list-container {
        float:left;

        .btn {
            border:1px solid $blue !important;
            color:$blue !important;
            border-radius: 0px !important;
            padding:10px 30px !important;
        }
    }
}

#EVIncentives {
    background-color: #fff !important;
    padding-top:50px;

    .title {
        p.h1 {
            margin-bottom: 50px !important;
        }
    }
}

#CostOfOwnership {
    padding:75px 0;
    background-color: #f3f7fb;

    .title {
        margin-bottom:50px;

        a {
            .btn-ae {
                display: flex;
                justify-content: center;
                align-items: center;                
                width: auto;
                min-width: 163px;
                height: 48px;
                background-color: $blue !important;
            }
        }
    }

    .input-well {
        .form-group {
            margin:25px 0 !important;

            label {
                font-weight: 400;
                margin-bottom: 10px;
            }
        }
    }
}

.btn-show-calc {
	border-bottom: 1px solid #DADADA !important;
	max-width: 75%;
	width: 75%;

	margin-left: auto;
	display: flex !important;

	.btn {
		border: none !important;
		font-weight: 600 !important;
		color: black !important;
		background-color: transparent !important;
		padding: 0 !important;
		margin: 0 !important;
		font-family: Avenir !important;
		margin: 0 0 8px !important;
	}

}

#incentives-page {
    background-color: #f3f7fb;

    .title {
        padding: 75px 0 50px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        h1 {            
            font-family: 'Avenir';
            font-style: normal;
            font-weight: 900;
            font-size: 42px;
            line-height: 48px;
            /* identical to box height, or 114% */

            display: flex;
            align-items: center;
            text-align: center;

            color: #333333;
        }

        h1, h2 {
            font-weight: bold;
            font-size:25px;
            text-align: center;
        }

        .contain-text {
            width:100%;
            margin-bottom: 15px;

            p {
                font-family: 'Avenir';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
                
                text-align: center;
                
                color: #333333;
            }
        }

        .btn.btn-ae {
            background-color: $blue;
            font-size:12px;
            padding:10px 40px;
        }
    }
}

.IncentivePreferences {
    .input-well {
        label {
            font-weight: 400;
            margin-bottom: 10px;
        }
    }

    .form-group {
        margin:25px 0;

        label {
            font-weight: 400;
            margin-bottom: 10px;
        }
    }
}

.CompareVehicles {
    .title {
        padding: 75px 0 50px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        h1, h2 {
            width:100%;
            font-weight: bold;
            font-size:25px;
            text-align: center;
        }

        .contain-text {
            width:100%;
            margin-bottom: 15px;

            p {
                padding-top:12px;
                max-width:700px;
                margin:0 auto;
                display:block;
                text-align: center;
                font-size:16px;
                font-weight: 300;
            }
        }

        .btn.btn-ae {
            background-color: $blue;
            font-size:12px;
            padding:10px 40px;
        }
    }
}

.chartTitle {    
    margin: 15px auto;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    display: block;
    align-items: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;

    color: #2B2B2B;
}

.graph-sub-title {    
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    align-items: center;
    text-align: center;

    color: #2B2B2B;
}

.input-value {    
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    color: #73767B;
}

.block-box-shadow {
    border-radius: 0;
    border: none;
    box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.1);

    h2 {                
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;

        color: #2B2B2B;
    }
}

.width-auto {
    width: auto;
}

label {    
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;

    color: #2B2B2B;
}
