@import "src/client_customizations/scss/client_master.scss";


section.home-banner {
	padding:50px 0;
	background-size: cover;
	background-position-y: bottom;
	min-height: 464px;
	background-color: #4279B3;

	@media(max-width:1250px) {
		min-height: 430px;
	}

	.container {
		display:flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		h1 {
			font-family: 'Avenir';
			font-style: normal;
			font-weight: 900;
			font-size: 60px;
			line-height: 64px;

			text-align: center;

			margin: 0 auto;

			color: #FFFFFF;
		}

		p {
			font-family: 'Avenir';
			font-style: normal;
			font-weight: 400;
			font-size: 32px;
			line-height: 44px;

			color: #FFFFFF;
		}

		a.banner-button {
			min-width: 255px;
			width: auto;
			height: 48px;

			display:flex;
			align-items: center;
			justify-content: center;
			padding:10px 20px;

			background-color:#0056B8;
			
			color:#fff;
			font-family: 'Avenir';
			font-style: normal;
			font-weight: 900;
			font-size: 16px;
			line-height: 22px;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

section.link-card-section {
	height: 263px;

	.container {
		display:flex;
		height: 100%;

		.box-container {
			width:25%;

			&.text {
				width: 255px;
				padding: 45px 20px 45px 0;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;

				h3 {
					font-family: 'Avenir';
					font-style: normal;
					font-weight: 900;
					font-size: 24px;
					line-height: 33px;
					letter-spacing: -0.02em;

					@media(max-width:800px) {
						font-size: 18px;
						line-height: 20px;
					}
				}

				p {
					@media(max-width:800px) {
						font-size: 9px;
					}
				}
			}
		}
	}
}

#HomepageVehiclesCarousel {
	background-image:url("../../assets/images/home-incentives-bg.png");
	background-size:cover;
	background-color: #4279B3;
	padding:75px 0;
	min-height: 833px;
	height: auto;

	.btn.btn-default {
		color: #000;
	}

	.title {
		display:flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 50px;

		h2 {
			display:block;
			width:100%;
			color:#fff;
			text-align: center;
			
			font-family: 'Avenir';
			font-style: normal;
			font-weight: 400;
			font-size: 32px;
			line-height: 48px;
		}

		.contain-text {
			width:100%;

			p {
				font-family: 'Avenir';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;

				color:#fff;
				text-align: center;

				max-width:650px;
				margin:0 auto 15px;
				
				display:block;
			}
		}

		a {
			margin:0 auto;
			background-color:#0056B8;
			font-size:12px;
			font-weight: bold;
		}
	}
}

#HomepageIncentives {
	padding:75px 0;

	.title {
		display:flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 50px;

		h2, h3 {
			display:block;
			width:100%;
			color:#333;
			text-align: center;
			font-size:25px;
		}

		.contain-text {
			width:100%;

			p {
				color:#333;
				text-align: center;
				color:18px;
				max-width:650px;
				margin:15px auto;
				display:block;
			}
		}

		a {
			margin:0 auto;
			background-color:#0056B8;
			font-size:12px;
			font-weight: bold;
		}
	}
}
