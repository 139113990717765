@import "src/client_customizations/scss/client_master.scss";
.MatchScorePopover.popover {
	max-width: 450px;
	padding: 12px 8px 0 8px;
	table {
		tbody {
			tr {
				&:last-child {
					border-top:$RenderItem-match-score-tr-last-child-border-top;
					border-bottom: $RenderItem-match-score-tr-last-child-border-bottom;
				}
			}
		}
		tr {
			th {
				&:first-child {
					padding-left: 0;
				}
				&:nth-child(2) {
					width: 100px;
					text-align: center;
					color: $RenderItem-match-score-tr-th-color;
					opacity: 0.45;
				}
				&:nth-child(3) {
					width: 100px;
					text-align: center;
				}
			}
			td {
				&:nth-child(2) {
					width: 100px;
					text-align: center;
					color: $RenderItem-match-score-tr-th-color;
					opacity: 0.45;
				}
				&:nth-child(3) {
					width: 100px;
					text-align: center;
				}
			}
		}
	}
}
.MatchScore{
    .ScoreValueGreaterThanEightyFive{
      background-color: $RenderItem-match-score-value-greaterThanEightyFive-color;
      color: #FFF;
    }
     .ScoreValueGreaterThanSeventy{
        background-color: $RenderItem-match-score-value-greaterThanSeventy-color;
        color: #FFF;
    }
    .ScoreValueLessThanSeventy{
        color: $RenderItem-match-score-value-lessThanSeventy-color;
    }

}
