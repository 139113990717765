@font-face {
  font-family: 'Avenir';
  src: url("./assets/fonts/Avenir Roman.otf")
    format("opentype");
}

@font-face {
  font-family: 'Avenir Black';
  src: url("./assets/fonts/Avenir Black.ttf")
    format("truetype");
}
