@import "src/client_customizations/scss/client_master.scss";

.ChargingMap {
	.spinner-container {
		margin: 0 auto;
		text-align: center;
	}
	.map-container {
		position: relative;
		height: 552px;
		width: 100%;
		border: $ChargingMap-map-container-border;
		border-radius: 4px;
	}
	.sidebar-container {
		position: absolute;
		left: 5px;
		top: 5px;
		z-index: 1;
	}
	.legend {
		background: $ChargingMap-legend-background;
		border: $ChargingMap-legend-border;
		border-radius: $ChargingMap-legend-border-radius;
		font-size: $ChargingMap-legend-font-size;
		.charger-type {
			position: relative;
			padding-right: 2rem;
			margin-bottom: 1rem;
			&:last-child {
				margin-bottom: 0;
			}
			img {
				width: 25px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
}
.DealerMap {
	.spinner-container {
		margin: 0 auto;
		text-align: center;
	}
	.map-container {
		position: relative;
		height: 552px;
		width: 100%;
		border: $ChargingMap-dealer-map-container-border;
		border-radius: $ChargingMap-dealer-map-container-border-radius;
	}
	.sidebar-container {
		position: absolute;
		left: 5px;
		top: 5px;
		z-index: 1;
	}
	.legend {
		background: $ChargingMap-dealer-legend-background;
		border: $ChargingMap-dealer-legend-border;
		border-radius: $ChargingMap-dealer-legend-border-radius;
		padding: 15px;
		font-size: 0.75rem;
		max-width: 199px;
		margin-bottom: 10px;
		position: relative;
		padding-right: 2rem;
		margin-bottom: 1rem;
		.charger-type {
			&:last-child {
				margin-bottom: 0;
			}
			img {
				width: 25px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
	.show-full-list-container {
		margin: 40px 0 25px 0;
		text-align: center;
	}
}
.Map {
	overflow: hidden;
}
.locationCardHolder {
	margin-top: 20px;
}
