@import "src/client_customizations/scss/client_master.scss";
.Footer {
	position: relative;
	background-color: #f3f3f3;
	padding:50px 0 0;
	color: $GenericFooter-color;
	.disclaimer_container {
		font-family: Arial, Helvetica;
		display: flex;
		// align-items: center;
		// margin-left: '5px' ;
		font-size: 10px; /* Set the font size to 10px */
		div {
			   background-color:transparent !important;
	
			   img {
					  max-width: 100%;
					  margin:0 0 20px;
			   }
	
			   p {
					  color:white;
			   }
		}
	}
	.top {
		position: relative;
		padding-bottom: 130px;

		@media(max-width:991px) {
			padding-bottom:200px;
		}
	}

	a {
		transition: 0.3s all ease;
	}

	.menu-column {
		width:16.6%;
		padding-right:25px;

		@media(max-width:991px) {
			width:50%;
			padding:0 20px;
		}

		&:last-child {
			padding-right:0px;
		}

		ul {
			padding:0;
			list-style-type: none;

			li {
				margin-bottom: 9px;

				a {
					display:block;
					color:#5c5c5c;
					line-height: 1.2em;
					margin-bottom:6px;
					font-size:13px;

					// transition: 0.3s all ease;
					// -webkit-transition: 0.3s all ease;
					// font-size: $GenericFooter-links-font-size;
					// line-height: $GenericFooter-links-line-height;
					// color: $GenericFooter-links-color;
 
					&:hover {
						text-decoration: underline;
					}

					&.black {
						color:#000;
						font-weight: bold;
						text-transform: uppercase;
						margin-bottom:10px;
						font-size:15px;

						&:hover {
							color: $GenericFooter-links-hover-color;
						}
					}
				}
			}
		}
	}

	#footer-bottom {
		line-height: 1;
		position: absolute;
		bottom: 45px;
		right: 0px;
		width: 59%;
		padding-right:0px;

		@media(max-width:1199px) {
			width:75%;
		}

		@media(max-width:991px) {
			padding-left:0px;
			width:100%;
		}

		.bg-blue {
			background-color: #0056b8;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left:-25px;
				bottom: 0;
				width: 40px;
				border-top: 28px solid #0056b8;
				border-right: 20px solid #0056b8;
				border-bottom: 28px solid transparent;
				border-left: 20px solid transparent;

				@media(max-width:991px) {
					display:none;
				}
			}

			.footer-quick-links {
				display: table-cell;
				vertical-align: middle;
				margin: 0;
				padding: 0;
				text-align: center;
				line-height: 1;
				padding:10px 50px 10px 0px;

				@media(max-width:991px) {
					width:100%;
					display:block;
					padding:10px;
				}

				li {
					display: inline-block;
					padding: 10px 20px;

					a {
						color:#fff;
						font-size:15px;
						font-weight: bold;
						text-transform: uppercase;

						&:hover {
							color: $GenericFooter-links-hover-color;
							text-decoration: none;
						}
					}
				}
			}

			#social-media {
				display: table-cell;
				vertical-align: middle;
				margin: 0px;
				padding: 0;
				text-align: center;
				line-height: 1;

				@media(max-width:991px) {
					width:100%;
					display:block;
					padding:10px;
				}

				li {
					display: inline-block;
					padding: 10px 5px;
					margin-right: 7px;

					svg {
						color:#fff;
						font-size:22px;
					}
				}
			}
		}
	}

	.disclaimer {
		p {
			font-size:12px;

			a {
				text-decoration: underline;
				color:#0056b8;
			}
		}
	}

	.bottom-bar {
		padding:30px 0;
		background-color: #5c5c5c;

		.container {
			display:flex;
		}

		.left, .right {
			width:110%;
		}

		.left {
			text-transform: uppercase;

			a {
				text-decoration: underline;
			}
		}

		.right {
			text-align: right;
		}

		p {
			color:#fff;
			margin:0;

			a {
				color: #FFF;
			}
		}
	}
}
