@import "src/client_customizations/scss/client_master.scss";

/* Apply yellow span underline for mobile views */
.Header {
	position: relative;
	z-index: 9;
	padding-top:15px;
	background-color: $Header-background-color;
	box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
	// border-bottom: $Header-border-bottom;
	a {
		color:$Header-link-color;

		span {
			padding-bottom:7px;
		}
	}

	.container {
		max-width: 1440px;
	}

	.top-bar {
		position: absolute;
		top:0;
		width:100%;
		padding:10px 0;
		z-index: 9;

		.container {
			display:flex;
			text-align: right;
			justify-content: flex-end;
			max-width: 1440px;
		}

		.toggle {
			padding:0 10px;

			&:last-child {
				padding-right:0px;
			}
			
			a {
				text-transform: uppercase;
				font-weight: bold;
				font-size:11px;

				&.active {
					color:#0056B8;
				}
			}
		}
	}

	.navbar {
		padding-top: 0;
		padding-bottom: 0;
		.navbar-brand {
			padding-top: 0;
			padding-bottom: 0;
			img {
				width: 169px;
				height: 126px;
			}
		}
		.navbar-collapse {
			a.active {
				span {
					color: #0056B8;
					text-decoration: none;
					border-bottom:4px solid #0056B8;
				}
			}

      .navbar-nav {
        align-items: center;

				.nav-item {
					margin-right: 29px;

					font-family: 'Avenir';
					font-style: normal;
					font-weight: 900;
					font-size: 18px;
					line-height: 25px;
					
					display: flex;
					align-items: center;
					text-align: right;
					
					color: #666666;

					@media(max-width:991px) {
						margin-bottom: 14px;
					}
				}

				&:last-child {
					margin-right: 86px;
				}
      }

      .dropdown-item {
        border-radius: 0;
      }
		}

    .dropdown-menu {
      top: 50px;
      border-radius: 3px;

      .dropdown-item {
        font-size: 13px;


        &.active,
        &:active {
          background-color: transparent;
          color: $Header-navbar-link-color;
        }
      }
    }
	}
}
.skip-to-content-link {
	position: absolute;
	top: -1000px;
	left: -1000px;
	height: 1px;
	width: 1px;
	text-align: left;
	overflow: hidden;
}
a.skip-to-content-link {
	&:active {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:focus {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:hover {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
}
.navbar-toggler {
	border-color: $Header-navbar-toggler-border-color;
	color:$Header-navbar-toggler-color;
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,86,184, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}
@media (min-width: 992px) {
	.Header {
		.navbar {
			padding: 0;
			.navbar-collapse {
				a {
					padding: 10px;
					border-radius: 4px;
					font-weight: 700;
					letter-spacing: 0.6px;
					text-decoration: none;
				}
				a.active {
					font-weight: bold;
				}
			}
		}
	}
}
