@import "src/client_customizations/scss/client_master.scss"; 

.CompareVehicles {
	position: relative;
	padding-top:0px;

	.container {
		background-color: transparent !important;
	}

	.blue-box {
		width:100%;
		height:500px;
		background-color:#f3f7fb;
		position: absolute;

		@media(max-width:768px) {
			display:none;
		}
	}

	.compared-vehicle-container {
		background-color:white;
		padding-top: 20px;
	}

	.ComparedCarDetails {
		.DetailColumn {
			padding:25px 0 !important;
	
			.title {
				padding:0;
				font-size:14px;
			}
	
			.h5 {
				font-weight: 600;
				font-size:20px;
			}
		}

		.btn-dte {
			background-color:white;
			color:$blue;
			border:1px solid $blue;

			&:hover {
				text-decoration: none;
				background-color:$blue;
				color:white;
			}
		}
	}

	.single-vehicle {
		padding-top: 50px;
		padding-bottom: 50px;

		h3 {
			margin-top:15px;
		}
	}

	.cost-of-ownership-container, .speed-of-charge-container, .electric-range-container {
		background-color:#f3f7fb;

		.title {
			display:block;
			padding:25px 20px;
			margin:0 !important;

			p.h1 {
				text-align: left;
				font-size:16px;
			}
		}
	}
}