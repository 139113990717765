@import "src/client_customizations/scss/client_master.scss";

.IncentiveCard.evc-card {
	flex-basis: 22%;
	margin-right: 3%;
	margin-bottom: 3%;
	border:none;
	box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
	border-top:5px solid #0056B8;
	border-radius: 0px;

	&:hover {
		border-left:none;
		border-right:none;
		border-bottom:none;
		border-top:5px solid #0056B8 !important;
		box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
	}

	hr {
		max-width:90%;
		margin:0 auto;
		border-top:1px solid #ccc;
	}
}
.IncentiveCardBody {
	padding: 1rem;
	text-align: center;
	color: $IncentiveCard-card-body-color;
	border-radius: 0;

	.card-title {
		margin:35px 0;

		font-family: 'Avenir';
		font-style: normal;
		font-weight: 400;
		font-size: 32px;
		line-height: 48px;
		/* identical to box height, or 150% */
		
		align-items: center;
		text-align: center;
		
		color: #333333;
	}

	.card-type {
		text-transform: uppercase;
		color:#333;

		font-family: 'Avenir';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		
		align-items: center;
		text-align: center;
		letter-spacing: 0.75px;
		text-transform: uppercase;
		
		color: #333333;
	}

	img {
		margin-top: -12px;
		margin-bottom: -16px;
		width: 60px;
	}
}
.IncentiveCardBottom {
	padding: 0.8rem;
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;	
	
	color: #000000;
}
@media (max-width: 991px) {
	.IncentiveCard.evc-card {
		flex-basis: 49%;
	}
}

@media (max-width: 550px) {
	.IncentiveCard.evc-card {
		flex-basis: 100%;
		margin-bottom: 25px;
	}
}
