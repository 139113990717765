@import "src/client_customizations/scss/client_master.scss";

.link-card-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	max-width: 400px;
	margin: 0 auto;
}
.link-card {
	height: 100%;

	display: flex;
	text-align: center;
	flex-shrink: 1;
	flex-basis: 80px;
	cursor: pointer;

	.full-width, .evc-card {
		max-width: 100%;
	}

	img {
		max-width: 80%;
		width: 100px;
	}
	p {
		margin-top: 0.5rem;
		margin-bottom: 0;
	}
	&:hover {
		p {
			text-decoration: none;
			color: $LinkCard-card-hover-body-color;
		}
	}

	.evc-card {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 255px;
		height: 100%;
		position: relative;
		background-color: #fff;
		border:none;
		// border: 1px solid #f2f2f2;
		// box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
		padding: 45px 10px;

		p {
			font-family: 'Avenir';
			font-style: normal;
			font-weight: 900;
			font-size: 18px;
			line-height: 24px;

			color:#0056B8;
			margin-top:15px;

			@media(max-width:800px) {
				font-size: 12px;
			}
		}

		&:before {
			position: absolute;
			content:"";
			width:100%;
			height:10px;
			display:none;
			left:0;
			top:-10px;
			background-color:#fff;
		}

		&:after {
			position: absolute;
			content:"";
			width:100%;
			height:10px;
			display:none;
			bottom:-10px;
			left:0;
			background-color:#fff;
		}

		&:hover {
			box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.1);
			border:none;

			&:before, &:after {
				display:block;
			}
			// box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
		}
	}
}