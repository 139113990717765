@import "src/client_customizations/scss/client_master.scss"; 


$CalcPanels-font-size: 14px; 
$CalcPanels-nav-button-font-size: 16px;
.CalcPanels {
	font-size: $CalcPanels-font-size;
	.nav-fill {
		display: flex;
		button {
			flex-grow: 1;
		}
	}
	.nav {
		button {
			font-size: $CalcPanels-nav-button-font-size;
			color: $CalcPanels-nav-button-font-color;
			border: 0;
			background-color: $CalcPanels-nav-button-background-color;
			display: block;
			padding: 0.5rem 1rem;
		}
		button.active {
			background-color: $blue !important;
			border-color: $CalcPanels-nav-button-border_color_active;
			color: white !important;
		}
	}
	.tab-pane {
		.table {
			text-align: center;
			th {
				&:first-child {
					width: 20%;
				}
				&:not(:nth-last-child) {
					width: auto;
				}
				&:last-child {
					width: 35%;
				}
			}
			td {
				&:first-child {
					text-align: left;
				}
				&:last-child {
					text-align: left;
				}
				.resale-vehicle-source {
					padding-bottom: 8px;
				}
			}
			tfoot {
				th {
					&:first-child {
						text-align: left;
					}
				}
			}
		}
	}
}

.btn-show-calc {
	border-bottom: 1px solid #DADADA !important;
	max-width: 75%;
	width: 75%;

	margin-left: auto;
	display: flex !important;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	.btn {
		border: none !important;
		font-weight: 600 !important;
		color: black !important;
		background-color: transparent !important;
		padding: 0 !important;
		margin: 0 !important;
		font-family: Avenir !important;
		margin: 0 0 8px !important;
	}

}
