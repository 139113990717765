@import "src/client_customizations/scss/client_master.scss";

.IncentivePreferences {
	.h5 {
		.badge {
			font-size: 50%;
			vertical-align: middle;
		}
	}
	.tooltip-bubble {
		min-width: 250px;
		max-width: 250px;
	}
	.input-well {
		background-color: $IncentivePreferences-input-well-background-color;
		padding: 0;
		border-radius: 0;
		border: none;
	}
}

.incentives-btn {
	width: 270px;
	height: 48px;
	background: #0056B8;
	margin: 0 auto;
}

.left-align {
	display: flex;
	justify-content: flex-start;
}

